
import { Component, Vue } from 'vue-property-decorator'
import { FileInfo } from '@/types/common'
import { ElForm } from 'element-ui/types/form'
import { DetailInfo, DataList } from '@/types/blackList'
import UploadFile from '@/components/uploadFile/Index.vue'

@Component({
  name: 'blackList',
  components: { UploadFile }
})
export default class extends Vue {
  private showDialog = false
  private searchInfo = {
    blackType: '',
    blackUserName: '',
    gender: '',
    status: ''
  }

  private formInfo: DetailInfo = {
    blackType: '0',
    blackUserName: '',
    gender: '',
    remark: '',
    resourceList: [],
    blackId: ''
  }

  private formRules = {
    resourceList: [
      { required: true, message: '请选择照片', trigger: 'change' }
    ]
  }

  private page = 1
  private size = 10
  private total = 0
  private tableData: DataList[] =[]
  private loading = false
  private titleDig = '新增黑名单'

  created () {
    this.getData()
  }

  // 查询
  searchData () {
    this.page = 1
    this.getData()
  }

  // 获取表格数据
  getData () {
    this.loading = true
    this.$axios
      .get<{ total: number; list: Array<DataList> }>(this.$apis.blackList.selectCameraBlackByPage, {
        ...this.searchInfo,
        page: this.page,
        size: this.size
      })
      .then((res) => {
        if (res) {
          this.total = res.total || 0
          this.tableData = res.list || []
        }
      })
      .finally(() => {
        this.loading = false
      })
  }

  // 新增黑名单
  addBlacks () {
    this.titleDig = '新增黑名单'
    this.showDialog = true
    this.formInfo.blackUserName = ''
    this.formInfo.gender = ''
    this.formInfo.remark = ''
    this.formInfo.resourceList = []
    this.formInfo.blackType = '0'
    delete this.formInfo.blackId
  }

  // 文件上传成功返回
  onSuccess (file: FileInfo) {
    this.formInfo.resourceList.push(file)
    this.$nextTick(() => {
      (this.$refs.DigForm as ElForm).clearValidate()
    })
  }

  imgRemove (index: number) {
    this.formInfo.resourceList.splice(index, 1)
  }

  editBlacks (row: DetailInfo) {
    this.titleDig = '编辑黑名单'
    this.showDialog = true
    this.formInfo = JSON.parse(JSON.stringify(row))
  }

  goDetail (id: string) {
    this.$router.push({ path: '/eventManage', query: { id } })
  }

  // 设置有效状态
  setEffect (blackId: string, status: number) {
    this.$confirm('确认将该黑名单设置为' + (status === 0 ? '无效' : '有效') + '?', '设置状态', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    })
      .then(() => {
        this.$axios.post(this.$apis.blackList.updateCameraBlackStatus, {
          blackId
        }).then(() => {
          this.$message.success('设置成功')
          this.searchData()
        })
      })
      .catch((err) => {
        console.log(err)
      })
  }

  onSubmit () {
    (this.$refs.DigForm as ElForm).validate((valid) => {
      if (valid) {
        const url = this.formInfo.blackId ? this.$apis.blackList.updateCameraBlack : this.$apis.blackList.insertCameraBlack
        this.$axios.post(url, this.formInfo)
          .then(() => {
            (this.$refs.DigForm as ElForm).resetFields()
            this.showDialog = false
            this.$message.success('保存成功')
            this.searchData()
          })
      }
    })
  }
}
